import { useRef, useEffect } from "react";
import { useColorScheme } from "../../styles/ColorSchemeContext.js";


import Navbar from "../../components/Navbar/Navbar";
import Logo from "../../components/Logo/Logo";
import ProjectDescription from "../../components/ProjectDescription/ProjectDescription.js";


import styles from "./ServicePage.module.scss";

const ServicePage = () => {
  const { colorScheme } = useColorScheme();
  const servicesElement = useRef(null);

  const typeText = (textArray, element, speed) => {
    let i = 0;
    element.innerHTML = "";
    const typing = setInterval(() => {
      element.innerHTML += textArray[i];
      i++;
      if (i === textArray.length) {
        clearInterval(typing);
      }
    }, speed);
  };



  useEffect(() => {
      const servicesList = [
        "Brand Strategy",
        "<br>",
        "Creative Direction",
        "<br>",
        "Communications",
        "<br>",
        "Visual Identity",
        "<br>",
        "Spatial Installations",
        "<br>",
        "VIDEO & PHOTOGRAPHY",
        "<br>",
        "Location Scouting",
        "<br>",
        "Production",
        "<br>",
        "Ar/Vr/Cgi",
      ];
    servicesElement.current &&
      typeText(servicesList, servicesElement.current, 20);
  }, []);

  return (
    <main style={{ color: colorScheme.textColor }}>
      <Navbar />

      <p ref={servicesElement} className="popupText"></p>

      <div className={styles.logo} style={{ fill: colorScheme.logoColor }}>
        <Logo />
      </div>
      {colorScheme.description && (
        <ProjectDescription description={colorScheme.description} />
      )}
    </main>
  );
};

export default ServicePage;
