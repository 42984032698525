import React, { createContext, useState, useContext } from "react";
import ColorSchemes from "./ColorSchemes";

const ColorSchemeContext = createContext();

export const ColorSchemeProvider = ({ children }) => {
  
  const getRandomColorScheme = (colorSchemes) => {
    return colorSchemes[Math.floor(Math.random() * colorSchemes.length)];
  }

  const startingColorSchemes = [ColorSchemes[0], ColorSchemes[1]];

  const [colorScheme, setColorScheme] = useState(getRandomColorScheme(startingColorSchemes));

  const toggleColorScheme = () => {
    const randomColorScheme = getRandomColorScheme(ColorSchemes);
    setColorScheme(randomColorScheme);
  };

  
  return (
    <ColorSchemeContext.Provider value={{ colorScheme, toggleColorScheme }}>
      {children}
    </ColorSchemeContext.Provider>
  );
};

export const useColorScheme = () => {
  return useContext(ColorSchemeContext);
};