import { useRef, useEffect } from "react";

import { useColorScheme } from "../../styles/ColorSchemeContext.js";


import styles from "./Background.module.scss";

const Background = () => {
  const { colorScheme } = useColorScheme();

  const bgElement = useRef(null);

 

  useEffect(() => {
    const handleVideoLoad = () => {
      console.log('handle');
          bgElement.current.style.display = "block";
          fadeIn(bgElement.current);
    }

     const fadeIn = (element) => {
       element.style.opacity = 0;
       let opacity = 0;
       const interval = setInterval(() => {
         if (opacity >= 1) clearInterval(interval);
         opacity += 0.05;
         element.style.opacity = opacity;
       }, 10);
     };

    if (bgElement.current) {
      const body = document.querySelector("body");
      body.style.backgroundColor = colorScheme.flashColor;
      
      if (colorScheme.type === "video") {
        bgElement.current.addEventListener("loadedmetadata", handleVideoLoad);
      } else {
        fadeIn(bgElement.current);
      }
    }

  }, [colorScheme]);

  if (colorScheme.type === 'color') {
    const bgColor = colorScheme.backgroundColor;
    return ( 
      <div 
        className={styles.bgColor} 
        style={{ background: bgColor, fill: 'orange'}} 
        ref={bgElement}
      ></div>
    );

  } else if (colorScheme.type === 'image') {
    return (
      <img
        src={colorScheme.imagePath}
        alt={colorScheme.name}
        className={styles.bgImage}
        ref={bgElement}
      ></img>
    );

  } else if (colorScheme.type === 'video') {

    return (
        <video
          src={colorScheme.videoPath}
          autoPlay
          muted
          loop
          playsInline
          height="100%"
          width="100%"
          className={styles.bgImage}
          ref={bgElement}
          preload="auto"
          style={{ display: "none", objectFit: "cover", borderRadius: '1px' }}
        ></video>
    );
  }
};

export default Background;
