import { Routes, Route } from "react-router-dom";

import IndexPage from "./pages/IndexPage/IndexPage";
import ClientsPage from "./pages/ClientsPage/ClientsPage";
import ServicePage from "./pages/ServicePage/ServicePage";
import ContactPage from "./pages/ContactPage/ContactPage";
import Background from "./components/Background/Background";

import "./App.css";

const App = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={ <IndexPage /> }/>
        <Route path="/clients" element={ <ClientsPage /> }/>
        <Route path="/services" element={ <ServicePage /> }/>
        <Route path="/contact" element={ <ContactPage /> }/>
      </Routes>
      <Background />
    </>
  );
};

export default App;
