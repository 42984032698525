import { Link } from 'react-router-dom';
import { useColorScheme } from '../../styles/ColorSchemeContext.js'

import styles from './Navbar.module.scss';

const Navbar = () => {
  const {  toggleColorScheme } = useColorScheme();
  return (
    <>
      <nav className={styles.navbar}>
        <div className={styles.logoAndLocations}>
          <Link to="/" onClick={() => toggleColorScheme()}>
            1KG
          </Link>
          <span>Paris Berlin Milan Amsterdam</span>
        </div>

        <span className={styles.studioDescription}>
          360° CREATIVE STUDIO FROM CONCEPTION TO EXPRESSION
        </span>

        <ul className={styles.navLinks}>
          <li>
            <Link to="/clients" onClick={() => toggleColorScheme()}>
              clients
            </Link>
          </li>
          <li>
            <Link to="/services" onClick={() => toggleColorScheme()}>
              services
            </Link>
          </li>
          <li>
            <Link to="/contact" onClick={() => toggleColorScheme()}>
              contact
            </Link>
          </li>
        </ul>
      </nav>
    </>
  );
}

export default Navbar;