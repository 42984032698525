const Logo = () => {
  return (<svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 1874.8 875.9"
    xmlSpace="preserve"
  >
    <g>
      <path d="M192.4,855.2V298H0V157.1c140.9-3.8,202.5-44,239-137.1h145.9v835.2H192.4z" />
      <path d="M624.4,344.5L931.3,20h245.3L865.9,349.5l327,505.6H951.4L723.7,500.5l-99.4,105.7v249h-200V20h200V344.5z" />
      <path
        d="M1866,265.3l-196.2,28.9c-17.6-62.9-78-119.5-186.2-119.5c-123.3,0-218.9,81.8-218.9,260.4c0,178.6,89.3,266.7,222.6,266.7
                    c91.8,0,187.4-35.2,187.4-142.1V547h-203.8V383.5h403.8v471.7h-152.2l-6.3-93.1c-55.3,73-138.4,108.2-259.1,108.2
                    c-250.3,0-403.8-164.8-403.8-435.2c0-267.9,174.8-428.9,432.7-428.9C1674.8,6.2,1825.8,100.5,1866,265.3z"
      />
    </g>
  </svg>);
}

export default Logo;

