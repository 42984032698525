const ColorSchemes = [
  {
    name: "white",
    type: "color",
    backgroundColor: "white",
    flashColor: "black",
    textColor: "black",
    isDesktopLogo: true,
    logoColor: "black",
  },
  {
    name: "black",
    type: "color",
    backgroundColor: "black",
    flashColor: "white",
    textColor: "white",
    isDesktopLogo: true,
    logoColor: "white",
  },
  // {
  //   name: "moncler1",
  //   type: "image",
  //   imagePath: "/images/Moncler.jpg",
  //   flashColor: "white",
  //   textColor: "white",
  //   isDesktopLogo: false,
  //   logoColor: "white",
  // },
  // {
  //   name: "moncler2",
  //   type: "video",
  //   videoPath: "/images/Moncler.mp4",
  //   flashColor: "white",
  //   textColor: "white",
  //   isDesktopLogo: false,
  //   logoColor: "white",
  // },
  // {
  //   name: "nike",
  //   type: "video",
  //   videoPath: "/images/Nike.mp4",
  //   flashColor: "#ffff00",
  //   textColor: "#FE7301",
  //   isDesktopLogo: false,
  //   logoColor: "#FE7301",
  // },
  // {
  //   name: "tnf",
  //   type: "video",
  //   videoPath: "/images/TNF5.mp4",
  //   flashColor: "white",
  //   textColor: "white",
  //   isDesktopLogo: false,
  //   logoColor: "white",
  // },
];

export default ColorSchemes;