import { useRef, useEffect, useState } from "react";

import { useColorScheme } from "../../styles/ColorSchemeContext.js";


import Navbar from "../../components/Navbar/Navbar";
import Logo from "../../components/Logo/Logo";

import styles from "./ContactPage.module.scss";

const ContactPage = () => {
  const { colorScheme, toggleColorScheme } = useColorScheme();

  const [isImprintActive, setIsImprintActive] = useState(false);
  const contactElement = useRef(null);

  const typeText = (textArray, element, speed) => {
    let i = 0;
    element.innerHTML = "";
    const typing = setInterval(() => {
      element.innerHTML += textArray[i];
      i++;
      if (i === textArray.length) {
        clearInterval(typing);
      }
    }, speed);
  };

  useEffect(() => {
    const contactList = [
      '<a class="contact-link" href="mailto:info@1-k-g.com" target="_blank">info@1-k-g.com</a>',
      "<br>",
      '<a class="contact-link" href="https://www.instagram.com/1kg_studio/" target="_blank">@1KG_Studio</a>',
    ];

    contactElement.current && typeText(contactList, contactElement.current, 40);
  }, []);

  return (
    <main style={{ color: colorScheme.textColor }}>
      <Navbar />

      <p ref={contactElement} className="popupText"></p>
      {isImprintActive && (
        <div className={styles.imprint}>
          <p>
            Angaben gemäß § 5 TMG<br></br>
            1kg Consulting GmbH<br></br>
            Zossener Strasse 41<br></br>
            10961 Berlin<br></br>
            <br></br>
            Handelsregister: HRB 183567B<br></br>
            Registergericht: Amtsgericht Charlottenburg<br></br>
            Vertreten durch die Geschäftsführer:<br></br>
            Oliver Kann, Arthur Groeneveld<br></br>
            Kontakt<br></br>
            E-Mail: info@1-k-g.com<br></br>
            <br></br>
            Umsatzsteuer-ID<br></br>
            Umsatzsteuer-Identifikationsnummer gemäß §27 a Umsatzsteuergesetz:
            <br></br>
            DE310663975<br></br>
            <br></br>
            Angaben zur Berufshaftpflichtversicherung<br></br>
            Name und Sitz des Versicherers:<br></br>
            AXA Versicherung AG<br></br>
            Colonia-Allee 10-20<br></br>
            51067 Köln<br></br>
            <br></br>
            Geltungsraum der Versicherung:<br></br>
            Deutschland,International<br></br>
            <br></br>
            Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV<br></br>
            1kg Consulting GmbH<br></br>
            Zossener Strasse 41<br></br>
            10961 Berlin<br></br>
            <br></br>
            Streitschlichtung<br></br>
            Die Europäische Kommission stellt eine Plattform zur
            Online-Streitbeilegung (OS) bereit:<br></br>
            https://ec.europa.eu/consumers/odr.<br></br>
            Unsere E-Mail-Adresse finden Sie oben im Impressum.<br></br>
            Wir sind nicht bereit oder verpflichtet, an
            Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle
            teilzunehmen.<br></br>
            Haftung für Inhalte<br></br>
            Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte
            auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach
            §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht
            verpflichtet, übermittelte oder gespeicherte fremde Informationen zu
            überwachen oder nach Umständen zu forschen, die auf eine
            rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung
            oder Sperrung der Nutzung von Informationen nach den allgemeinen
            Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist
            jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten
            Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden
            Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
            <br></br>
            <br></br>
            Haftung für Links<br></br>
            Unser Angebot enthält Links zu externen Websites Dritter, auf deren
            Inhalte wir keinen Einfluss haben. Deshalb können wir für diese
            fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der
            verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber
            der Seiten verantwortlich. Die verlinkten Seiten wurden zum
            Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft.
            Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht
            erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten
            Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung
            nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir
            derartige Links umgehend entfernen.<br></br>
            <br></br>
            Urheberrecht<br></br>
            <br></br>
            Die durch die Seitenbetreiber erstellten Inhalte und Werke auf
            diesen Seiten unterliegen dem deutschen Urheberrecht. Die
            Vervielfältigung, Bearbeitung, Verbreitung und jede Art der
            Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der
            schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
            Downloads und Kopien dieser Seite sind nur für den privaten, nicht
            kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser
            Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte
            Dritter beachtet. Insbesondere werden Inhalte Dritter als solche
            gekennzeichnet. Sollten Sie trotzdem auf eine
            Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
            entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen
            werden wir derartige Inhalte umgehend entfernen.
          </p>
        </div>
      )}

      <button
        className={styles.imprintButton}
        onClick={() => {
          setIsImprintActive(!isImprintActive);
          toggleColorScheme();
        }}
      >
        Imprint
      </button>

      <div className={styles.logo} style={{ fill: colorScheme.logoColor }}>
        <Logo />
      </div>
    </main>
  );
};

export default ContactPage;
