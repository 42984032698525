import { useState, useEffect } from "react";
import { useColorScheme } from "../../styles/ColorSchemeContext.js";

import Navbar from "../../components/Navbar/Navbar";
import Logo from "../../components/Logo/Logo";
import ProjectDescription from "../../components/ProjectDescription/ProjectDescription.js";

import styles from "./IndexPage.module.scss";

const IndexPage = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);
  const { colorScheme } = useColorScheme();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <main className={styles.mainPage} style={{color: colorScheme.textColor}}>
      <Navbar />

      <div className={styles.bodyStudioDescription}>
        360° CREATIVE STUDIO FROM CONCEPTION TO EXPRESSION
        <span className={styles.bodyStudio}>
          , BASED IN PARIS, BERLIN, MILAN & AMSTERDAM
        </span>
      </div>

      {(isMobile || colorScheme.isDesktopLogo) ? 
      <div className={styles.logo} style={{fill: colorScheme.logoColor}}>
        <Logo /> 
      </div> : null}
      {colorScheme.description && <ProjectDescription description={colorScheme.description} />}
    </main>
  );
};

export default IndexPage;
